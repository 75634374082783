<template>
    <div class="v-form v-form__check check">
        <h1>Ввод вручную</h1>
        <div class="check__wrap manual_check">
            <div class="check-container-input">
                <!-- <p class="check__subtitle">Сумма</p> -->
                <v-text-field
                    outlined
                    required
                    label="Сумма"
                    clearable
                    class="check-input"
                    :class="{'errorDate': !valid_manual_sum}"
                    v-model="manual_sum"
                    dense
                ></v-text-field>
            </div>
            <div class="check-container-input">
                <!-- <p class="check__subtitle">Дата и время</p> -->
                <v-text-field
                    outlined
                    label="Дата и время"
                    required
                    clearable
                    v-model="manual_date"
                    class="check-input"
                    :class="{'errorDate': !dateValidate}"
                    dense
                    v-mask="'##.##.#### ##:##'"
                ></v-text-field>
            </div>
            <div class="check-container-input">
                <!-- <p class="check__subtitle">ФН №</p> -->
                <v-text-field
                    outlined
                    label="ФН №"
                    required
                    clearable
                    class="check-input"
                    :class="{'errorDate': !valid_manual_fn}"
                    v-model="manual_fn"
                    dense
                ></v-text-field>
            </div>
            <div class="check-container-input">
                <!-- <p class="check__subtitle">ФД №</p> -->
                <v-text-field
                    outlined
                    required
                    label="ФД №"
                    clearable
                    class="check-input"
                    :class="{'errorDate': !valid_manual_fd}"
                    v-model="manual_fd"
                    dense
                ></v-text-field>
            </div>
            <div class="check-container-input">
                <!-- <p class="check__subtitle">ФП №</p> -->
                <v-text-field
                    outlined
                    required
                    label="ФП №"
                    clearable
                    class="check-input"
                    :class="{'errorDate': !valid_manual_fp}"
                    v-model="manual_fp"
                    dense
                ></v-text-field>
            </div>
            
        </div>
        <v-btn
            depressed
            color="primary"
            class="next-btn check-btn"
            @click="manual_send"
            block
        >
        <!--    :disabled="!(manual_date!='' && manual_fd!='' && manual_fn!='' && manual_fp!='' && manual_sum!='')" -->
            <div class='btn-content-container'>
                <img src="@/assets/loader.svg" alt="loader" class="loader-icon"  :class="{'hidden': !isLoading}"/>
                Отправить
            </div></v-btn>
    </div>
            
</template>
<script>
import jwt_decode from 'jwt-decode'
import {mapActions} from 'vuex'
import moment from 'moment'
export default {
    data(){
        return {
            isLoading: false,
            dateValidate: true,
            manual_sum:'',
            valid_manual_sum: true,
            valid_manual_fn: true,
            valid_manual_fd: true,
            valid_manual_fp: true,
            manual_date:'',
            manual_fn:'',
            manual_fd:'',
            manual_fp:'',
            user_id: jwt_decode(this.$cookies.get("cng_token"))["id"],
        }
    },
    methods: {
        validateDate(){
            if(this.manual_date !== null){
                const date = moment(moment(this.manual_date, "DD.MM.YYYY HH:mm").format("YYYY-MM-DDTHH:mm"))
                const time = this.manual_date.split(' ')[1]
                const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(time)
                
                if(new Date(moment(this.manual_date, "DD.MM.YYYY HH:mm").format())<new Date()){
                    if(date.isValid() && isValid){
                        this.dateValidate = true
                        return true
                    } else {
                        this.dateValidate = false
                        return false
                    }
                } else{
                    this.dateValidate = false
                    return false
                }
            } else {
                return false
            }
            
        },
        manual_send(){
            this.isLoading = true
            if(process.env.VUE_APP_ENV_PROJECT === 'demo'){
                this.startDemo()
            } else {
                if(this.validateDate() && this.manual_sum !== '' && this.manual_fn !== ''  && this.manual_fd !== '' && this.manual_fp !== ''
                && this.manual_date !== null && this.manual_sum !== null && this.manual_fn !== null  && this.manual_fd !== null && this.manual_fp !== null){
                    const date =  this.manual_date.split(' ')[0].replace(/\./g,'')+'T'+this.manual_date.split(' ')[1].replace(':','')
                    let formData = new FormData();
                    formData.append("account_id", this.user_id);
                    formData.append("summary", this.manual_sum);
                    formData.append("time", date);
                    formData.append("fn", this.manual_fn);
                    formData.append("fd", this.manual_fd);
                    formData.append("fp", this.manual_fp);
                    formData.append("type", 'manual');
                    fetch(`${process.env.VUE_APP_QR}`, {
                        method: "POST",
                        body: formData
                    })
                        .then(response => {
                            if(response.status == 400){
                                alert('Error')
                                this.isLoading = false
                            }
                            else if(response.ok){
                                if(this.$store.state.isActivetedUser){
                                    this.isLoading = false
                                    this.$router.push({name:'LcSuccess'})
                                } else {
                                    this.isLoading = false
                                    this.$router.push({name:'first_check'})
                                }
                            }
                        return response.json();
                    });
                } else {
                    if(this.manual_sum === '' || this.manual_sum === null){
                    this.valid_manual_sum = false
                    }
                    if(this.manual_fd === '' || this.manual_fd === null){
                        this.valid_manual_fd = false
                    }
                    if(this.manual_fn === '' || this.manual_fn === null){
                        this.valid_manual_fn = false
                    }
                    if(this.manual_fp === '' || this.manual_fp === null){
                        this.valid_manual_fp= false
                    }
                    this.isLoading = false
                }
            }
        },
        startDemo(){
            this.isLoading = true
            console.log(this.manual_date)
            // const currentDate = moment().format("YYYYMMDDTHHmm")
            if(this.manual_date !== null && this.validateDate() && this.manual_sum !== '' && this.manual_fn !== ''  && this.manual_fd !== '' && this.manual_fp !== ''
             && this.manual_sum !== null && this.manual_fn !== null  && this.manual_fd !== null && this.manual_fp !== null){
                const date =  this.manual_date.split(' ')[0].replace(/\./g,'')+'T'+this.manual_date.split(' ')[1].replace(':','')
                const data = {
                    account_id: this.user_id,
                    summary: this.manual_sum,
                    time: date,
                    fn: this.manual_fn,
                    fd: this.manual_fd,
                    fp: this.manual_fp,
                    type: 'manual'
                }
               
                fetch(`${process.env.VUE_APP_NT}/demo`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(data)
                    }).then(response => {
                        
                            return response.json();
                        })
                        .then(data => {
                            if(data.status == 400){
                                alert('Error')
                                this.isLoading = false
                            }
                            else {
                                if(data.data){
                                    this.getUserNotification()
                                    this.isLoading = false
                                    this.$router.push('/success')
                                } else {
                                    this.isLoading = false
                                    this.$router.push('/success')
                                }
                            }
                });
            } else {
                if(this.manual_sum === '' || this.manual_sum === null){
                    this.valid_manual_sum = false
                }
                if(this.manual_fd === '' || this.manual_fd === null){
                    this.valid_manual_fd = false
                }
                if(this.manual_fn === '' || this.manual_fn === null){
                    this.valid_manual_fn = false
                }
                if(this.manual_fp === '' || this.manual_fp === null){
                    this.valid_manual_fp= false
                }
                this.isLoading = false
            }
        },   
        ...mapActions({
            getUserNotification: "getUserNotification",     
            getUserData: 'getUserData'
        })
        
    },
    watch:{
        manual_date(){
            if(this.manual_date == ''){
                this.dateValidate = true
            } else {
                const date = moment(moment(this.manual_date, "DD.MM.YYYY HH:mm").format("YYYY-MM-DDTHH:mm"))
                if(date.isValid()){
                    this.dateValidate = true
                } else {
                    this.dateValidate = false
                }
            }
        },
        manual_fd(){
            this.valid_manual_fd = true         
        },
        manual_fn(){
            this.valid_manual_fn = true
        },
        manual_fp(){
            this.valid_manual_fp = true
        },
        manual_sum(){            
            this.valid_manual_sum = true
            
        }
    },
    mounted() {
    //    this.getUserData()
   }
}
</script>
<style scoped>
    .v-form.v-form__check.check{
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.005em;
        gap: 10;
        color: #1A1A1A;
    }
    .v-form.check h1{
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        /* identical to box height, or 20px */

        text-align: center;
        letter-spacing: -0.005em;
        margin-bottom: 50px;
        color: #1A1A1A;
    }
    .check__wrap{
        gap: 0;
    }
    .check-container-input{
        margin-bottom: 5px;
    }
    .check__subtitle{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;

        letter-spacing: -0.015em;

        color: #1A1A1A;
        margin-bottom: 4px !important;
     
    }
    .check-btn{
        margin-top: 0;
    }
    .check-input.v-input input{
       
    }

</style>