<template>
    <div class="wrapper">
         <HeaderNav :auth="$store.state.isAuth"></HeaderNav>
    <div class="v-form v-form__accept">
        <div class="accept__sub">
            <h1>Для участия в розыгрыше отсканируйте свой чек</h1>
        </div>
        <div class="accept__btns">
            <v-btn
                depressed
                color="primary"
                class="next-btn btn__scan"
                block
                @click="$router.push({name:'LcScan'})"
                >
                <div class='btn-content-container'>
                    <img src="@/assets/loader.svg" alt="loader" class="loader-icon"  :class="{'hidden': !isLoading}"/>
                    Сканировать чек
                </div>
            </v-btn>
            <p class="politic">Предоставьте приложению доступ к камере</p>
        </div>
    </div>
</div>
</template>
<script>
import HeaderNav from '@/components/HeaderNav.vue';

export default {
    data(){
            return{
                isLoading: false
            }
    },
    components: { HeaderNav }
}
</script>
<style lang="scss" scoped>
   .wrapper {
        width: 100%;
        margin: 0px 0 0 0 !important;
        padding: 0  !important;
    }
    
    .v-form h1{
        font-family: 'PT Sans';
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: -0.005em;
        text-align: center;

    }
    .title__wrap {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 32px;
    }
    .btn-content-container{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .loader-icon{
        margin-right: 10px;
        visibility: visible;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    .loader-icon.hidden{
       visibility: hidden;
    }

    .v-form, 
    .form {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .v-form {
        background: #fff;
        width: 100%;
        padding: 32px 20px;
        border-radius: 16px;
    }
    .next-btn {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 12px;
    
    }
    .accept__sub{
        margin-bottom: 32px;
    }

    .accept__btns{
    
    }
    .accept__btns button.next-btn.btn__scan.v-btn.v-btn--block.v-btn--has-bg.theme--light.v-size--default.primary {
    width: 100% !important;
    background-color: #F77988 !important;
    height: 44px;
    margin-top: 0px;
    margin-bottom: 12px !important;
}
    .forgotPassword{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.015em;

        color: #4F70E4;
    }
    .politic{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.015em;

        color: #868686;
    }
</style>