<template>
    <div class="header__wrap">
        <v-toolbar
            dense
            class="header"
        >
        <img src="../assets/logo.svg" alt="логотип" class="header__img" @click="$router.push({name:'LcMain'})">
        <div class="header__seting" >
            <div v-if="auth" class="avatar-container">
                <img :src="avatar" v-if="$store.state.avatar!=='' && $store.state.avatar!==undefined && $store.state.avatar!==null" alt="фото" class="header__avatar"  @click="clickAvatar">
                <img src="../assets/avatar-test.svg"  v-else alt="фото" class="header__avatar" @click="clickAvatar">
            </div>
            <div  v-if="!auth" class="link-container">
                <a :href="'mailto:support@checkngo.ru'" target="_blank">поддержка</a>
            </div>
            <v-app-bar-nav-icon v-if="auth" class="header-menuBtn" @click.stop="$store.commit('changeVisibleSidebar', true)"></v-app-bar-nav-icon>
        </div>
        </v-toolbar>
    </div>
</template>

<script>
    export default {
        props: {
            auth: {type: Boolean, required: true}
        },
        data(){
            return{
                avatar: this.baseUrl()
            }
        },
        methods: {
            validatePass(){
                this.$cookies.remove('cng_token')
                this.$store.commit('changeQrcodes', [])
                this.$router.push({name:'login'})
            },
            clickAvatar(){
                if(this.$store.state.isActivetedUser){
                    this.$router.push({name:'profile'})
                } else {
                    this.$router.push({name:'apruve-email'})
                }
            },
            baseUrl(){
                return `${process.env.VUE_APP_AVATARS}/${this.$store.state.avatar}`
        }
        },
        computed:{

        },
        watch:{
            '$store.state.avatar': function() {
                this.avatar = this.baseUrl()
            },
            avatar(){
                this.avatar = this.baseUrl() 
            }

        },
        mounted(){
          
        }
    }
</script>

<style>

    .header {
        width: 100%;
        background: #fff !important;
        height: 48px;
    }

    .header__wrap {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
    }
    
    .v-toolbar__content {
        padding: 0 5px 0 16px !important;
        /* при авторизации и регистрации
        justify-content: center;
        */
        justify-content: space-between;
    }

    .header-menuBtn{
        color: #798DF7 !important;
    }
    .v-icon.notranslate.mdi.mdi-menu.theme--light{
        color: #798DF7 !important;

    }
    .header__img{
        width: 30px;
        height: 30px;
    }
    .header__seting {
        display: flex;

        /* gap: 10px; */
        align-items: center;
    }
    .avatar-container{
        display: flex;
        align-items: center;
    }
    .header__avatar{
        cursor: pointer;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        object-fit: cover;
        /* margin-right: 10px; */
    }
    .link-container{
        display: flex;
        align-items: center;
        margin-right: 11px;
    }
    .link-container a{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.015em;
        text-decoration-line: none;
        cursor: pointer;
        color: #4F70E4 !important;
        padding-bottom: 1px;
        border-bottom: 1px solid #4F70E4;
    }
    .v-app-bar__nav-icon.v-btn{
        margin-top: 0;
        /* width: 24px !important; */
    }

    .header__seting p {
        font-size: 12px;
        cursor: pointer;
    }
    
    .v-sheet.v-toolbar:not(.v-sheet--outlined) {
        box-shadow: none;
    }

</style>
