<template>
    <div class="plug-wrapper">
        <!-- <div class="plug-header">
            <img class="plug-header__logo" src="@/assets/logo.svg" alt="logo" />
            <div class="plug-header-nuvbar">
                <span class="plug-header-nuvbar__item">Для агентств</span>
                <span class="plug-header-nuvbar__item">О решении</span>
                <span class="plug-header-nuvbar__item">Контакты</span>
            </div>
            <button class="plug-header__btn">получить предложение</button>
        </div> -->
        <div class="plug-body">
            <div class="plug-body-left">
                <h1>
                    платформа 
                    Check’n’go
                    <!-- приложение доступно только на&nbsp;смартфоне -->
                </h1>
                <span>
                    Чтобы воспользоваться сервисом, пожалуйста, скопируйте ссылку
                </span>
                <div class="copy-ling-block">
                    <input 
                    ref="input" 
                    value="demo.checkngo.ru"
                    readonly 
                
                    v-on:focus="$event.target.select()" 
                    />
                
                    <img src="@/assets/copy_icon.svg" alt="copy" class="copy-ling-block__img" @click="copy"/>
                </div>
            </div>
            <div class="plug-body-right">
                <div class="plug-body-right__block">
                    Или отсканируйте QR-код на&nbsp;мобильном устройстве
                </div>
                <div class="plug-body-right__qr">
                    <img src="@/assets/demo.checkngo.ru.qr.svg" alt="qr" />
                    <div class="plug-body-right__qr__elem-top-right"></div>
                    <div class="plug-body-right__qr__elem-top-left"></div>
                    <div class="plug-body-right__qr__elem-bottom-left"></div>
                    <div class="plug-body-right__qr__elem-bottom-right"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


    export default {
        data() {
        return {
            isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i
            .test(navigator.userAgent),
            windowWidth: window.innerWidth
        }
        },
        methods:{
            onResize() {
            this.windowWidth = window.innerWidth
            this.redirect()
            },
            redirect(){
            if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i
            .test(navigator.userAgent))){
                this.$router.push({ name: "Start" });
            }
            },
            async copy(){
                this.$refs.input.focus();
                document.execCommand('copy');
                await this.$nextTick()
                this.$refs.input.blur();
            }
        },
        watch:{

        },
        mounted(){
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
            })
            console.log((/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i
            .test(navigator.userAgent)))
            this.redirect()
        },
        beforeDestroy() { 
            window.removeEventListener('resize', this.onResize); 
        },  
    }
</script>

<style lang="scss" scoped>
    .plug-wrapper{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        // max-width: 100%;
        min-width: 1440px;
        overflow-y: auto;
        min-height: 100vh;
        padding: 60px 40px 60px 40px;
    }

    @media (max-width: 1360px) {
        .plug-body{
            align-items: start;
        }
    }

    .plug-header{
        position: relative;
        width: 100%;
        min-width: 720px;
        height: 78px;
        margin-bottom: 44px;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        background: #FFFFFF;
        border-radius: 16px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1F1F30;

    }
    .plug-header__logo{
        width: 58px;
        height: 58px;
        margin-right: 52px;
    }
    .plug-header-nuvbar{
        display: flex;
        align-items: center;
        min-width: 220px;
        width: 50%;
    }
   .plug-header-nuvbar__item{
        margin-right: 40px;
        cursor: pointer;

   }
   .plug-header__btn{
        position: absolute;
        right: 20px;
        width: 240px;
        height: 38px;
        background: #1F1F30;
        border: 1px solid #1F1F30;
        border-radius: 200px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        text-transform: uppercase;

        color: #FFFFFF;
   }
    .plug-body{
        position: relative;
        width: 100%;
        // margin-top: 30px; // УДАЛИТЬ КАК ПОЯВИТСЯ ШАПКА
        min-width: 1360px;
        height: 711px;
        background: #FFFFFF;
        border-radius: 16px;
        padding: 57px 35px 57px 93px;
        display: flex;
        // flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .plug-body-left{
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        width: 635px;
        height: 100%;
        // padding-top: 105px;
        margin-right: 54px;
    }
    .plug-body h1{
        font-family: 'Korataki';
        font-style: bold;
        font-weight: 700;
        font-size: 69px;
        line-height: 140%;
        width: 635px;
        margin-bottom: 65px;
        text-align: start;

        color: #1F1F30;
    }

    .plug-body span{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 31px;
        color: #1F1F30;
        margin-bottom: 33px;
        text-align: start;
    }
    .copy-ling-block{
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        color: #1F1F30;
        padding: 10px 30px 10px 40px;
        width: 100%;
        background: #FFFFFF;
        border: 2px solid #CCCCCC;
        border-radius: 10px;
        // text-align: start;
    }
    .copy-ling-block input{
          display: flex;
        align-items: start;
        width: 80%;
        line-height: 48px;
        height: 100% !important;
        outline: none;
        border: none;
    }
    .copy-ling-block__img{
        position: relative;
        // width: 50px;
        // height: 50px;
        // right: 30px;
        // top: 15px;
        cursor: pointer;
        padding: 10px;
    }
    .plug-body-right{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
    }
    .plug-body-right__block{
        position: relative;
        max-width: 543px;
        width: 543px;
        height: 178px;
        background-image: url('@/assets/message.png');
        background-repeat: no-repeat;
        font-family: 'Inter';
        padding: 30px;
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 31px;
        text-align: center;
        padding-bottom: 80px;
        color: #1F1F30;
    }
    .plug-body-right__qr{
        position: relative;
        // text-align: start;
        width: 543px;
        padding: 20px;
        height: 100%;
        // margin-top: 10px;
    }
    .plug-body-right__qr img{
        position: relative;
        // text-align: start;
        width: 392px;
        // height: 100%;
        // margin-top: 10px;
    }
    .plug-body-right__qr__elem-top-right{
        position: absolute;
        background-image: url('@/assets/Vector_plugpage.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 60px;
        height: 60px;
        top: 5px;
        right: 59px;
    }
    .plug-body-right__qr__elem-top-left{
        position: absolute;
        position: absolute;
        background-image: url('@/assets/Vector_plugpage.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 60px;
        height: 60px;
        transform: rotate(-90deg);
        top: 5px;
        left: 59px;
    }
    .plug-body-right__qr__elem-bottom-left{
        position: absolute;
        position: absolute;
        background-image: url('@/assets/Vector_plugpage.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 60px;
        height: 60px;
        transform: rotate(180deg);
        bottom: 10px;
        left: 59px;

    }
    .plug-body-right__qr__elem-bottom-right{
        position: absolute;
        position: absolute;
        background-image: url('@/assets/Vector_plugpage.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 60px;
        height: 60px;
        transform: rotate(90deg);
        bottom: 10px;
        right: 59px;
    }

</style>