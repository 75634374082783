<template>
    <div>
        <HeaderNav :auth="false"></HeaderNav>
        <div class="wrapper startView">
            <div class='container'>
 
                <div class="title__wrap">
                    <h1>Регистрируйте чеки<br>&nbsp;для участия в&nbsp;акции!</h1>
                    <p>Получите шанс выиграть один из&nbsp;1000 призов на&nbsp;сумму более 1&nbsp;000&nbsp;000 рублей</p>
                </div>
                
                    <v-btn
                        depressed
                        color="primary"
                        class="next-btn start"
                        block
                        @click="$router.push({name:'login'})"
                        >Начать</v-btn>
                    <v-btn
                        depressed
                        color="primary"
                        class="about-btn"
                        block
                        @click="$router.push({name:'TermsPromo'})"
                        >Условия акции</v-btn>

            </div> 
        </div>
    </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue';

    export default {
    components: { HeaderNav },
    methods:{
        pushInPromo(){
            sessionStorage.setItem('lastPath', this.$route.path)
            this.$router.push({name:'TermsPromo'})
        }
    }
}
</script>

<style>
    .password-error {
        display: none;
        font-weight: 400;
        font-size: 12px !important;
        text-align: left;
        color: #DB4100;
        letter-spacing: -0.015em;
        margin-top: -16px;
    }

    .recovery {
        font-family: 'Inter';
        margin-top: -16px !important;
        font-size: 12px;
        color: #4F70E4;
        font-weight: 500;
        cursor: pointer;
    }

    .wrapper {
        width: 100%;
        /* margin:  0 !important;
        padding: 0  !important; */
    }

    .container{
        display: flex;
        flex-direction: column;
        gap: 0px;
        background: #fff;
        width: 100%;
        padding: 32px 20px;
        border-radius: 16px;
    }
    .container h1{
        font-family: 'PT Sans';
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: -0.005em;
        text-align: center;

    }

    .v-form, 
    .form {
        display: flex;
        flex-direction: column;
        gap: 32px !important;
    }

    .v-form {
        background: #fff;
        width: 100%;
        padding: 32px 20px;
        border-radius: 16px;
    }

    .next-btn {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }

    .v-btn:not(.v-btn--round).v-size--default.about-btn {
        background: none !important;
        color: #89B924 !important;
        font-weight: 700;
        font-size: 16px;
        border: 1px solid #89B924 !important;
        border-radius: 12px;
        line-height: 24px;
    }
    .v-btn:not(.v-btn--round).v-size--default.about-btn .v-btn__content{
        color: #89B924 !important;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }

    .startView .about-btn{
        margin-top: 0 !important;
    }

    .v-text-field__slot {
        height: 44px;
    }

    .title__wrap {
        display: flex;
        flex-direction: column;
        gap: 18px;
        margin-bottom: 28px;
  
    }

    .title__wrap h1{
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.005em;

        color: #1A1A1A;
    }

    .title__wrap p{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.015em;

        color: #1A1A1A;
    }

  

    .v-icon.v-icon.v-icon--link {
        color: #1A1A1A !important;
        font-size: 20px !important;
    }
    .check .v-icon.v-icon.v-icon--link {
        color: #CCCCCC !important;
        font-size: 20px !important;
    }

    .v-text-field--outlined fieldset {
        border-radius: 6px;
        border-color: #CCCCCC !important;
    }

    fieldset.valid {
        border-color: #51AD30 !important;
    }

    fieldset.err {
        border-color: #DB4100 !important;
    }

    .v-btn {
        margin-top: 8px;
        height: 44px !important;
    }
    .next-btn.start{
        margin-top: 0;
        margin-bottom: 12px;
    }
    .v-btn--disabled {
        background: #CDD6CA;
        border-radius: 6px;
    }

     .v-btn__content {
        font-weight: 500;
        font-size: 16px !important;
        color: #fff;
        letter-spacing: -0.015em;
        text-transform: initial;
     }

    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled) > .v-input__control > .v-input__slot:focus fieldset,
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled) > .v-input__control > .v-input__slot:active fieldset
    /* .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled) > .v-input__control > .v-input__slot:hover fieldset  */
       { 
    border-color: #1A1A1A !important;
    }

    .v-btn:not(.v-btn--round).v-size--default {
        background: #89B924 !important;
        border-radius: 12px;
    }

    .inputs {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .active {
        display: block;
    }

    .hidden {
        display: none;
    }

    .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner {
        margin-top: 10px;
    }

    .reg__completed {
        margin-top: 16px;
        margin-bottom: 32px;
    }
</style>