import Vue from 'vue'
import VueRouter from 'vue-router'
import NewLoginView from '../views/NewLoginView.vue'
import StartView from '../views/StartView.vue'
import TermsPromoView from '../views/TermsPromotionView.vue'
import LcView from '../views/LC/LcView.vue'
import WrapperView from '../views/LC/WrapperView.vue'
import ManualView from '../views/LC/ManualView.vue'
import ScanView from '../views/LC/ScanView.vue'
import SingleView from '../views/LC/SingleView.vue'
import SuccessView from '../views/LC/SuccessView.vue'
import UploadView from '../views/LC/UploadView.vue'
import RecoveryView from '../views/RecoveryView.vue'
import LinkAuthComponent from '../components/LinkAuthComponent.vue'
import ApruveView from '../views/ApruveAccountView.vue'
import ApruveEmailView from '../views/LC/ApruveEmailView.vue'
import FirstScan from '@/views/ViewForNewAccount/FirstScan.vue'
import FirstCheck from '@/views/ViewForNewAccount/FirstCheckView.vue'
import ProfileView from '@/views/LC/ProfileView.vue'
import RulesDrawView from '@/views/RulesDraw.vue'
import SupportView from '@/views/SupportView.vue'
import NotificationsView from '@/views/NotificationsView.vue'
import NotificationSingleView from '@/views/NotificationSingleView.vue'
import DesktopPlug from '@/views/DesktopPlug.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Start',
    component: StartView
  },
  {
    path: '/termspromo',
    name: 'TermsPromo',
    component: TermsPromoView
  },
  {
    path: '/rulesdraw',
    name: 'RulesDraw',
    component: RulesDrawView
  },
  {
    path: '/login',
    name: 'login',
    component: NewLoginView
  },
  {
    path: '/support',
    name: 'support',
    component: SupportView
  },
  {
    path: '/notification',
    name: 'notifications',
    component: NotificationsView
  },
  {
    path: '/desktop_plug',
    name: 'desktop_plug',
    component: DesktopPlug
  },
  
  {
    path: '/notification/:id',
    name: 'NotificationSingle',
    component: NotificationSingleView
  },
  {
    path: '/lc',
    name: 'lc',
    component: WrapperView,
    children: [
      {
        path: '/',
        name: 'LcMain',
        components: {
          LcMenu: LcView
        }
      },
      {
        path: '/manual',
        name: 'LcManual',
        components: {
          LcMenu: ManualView
        }
      },
      {
        path: '/scan',
        name: 'LcScan',
        components: {
          LcMenu: ScanView
        }
      },
      {
        path: '/single/:id',
        name: 'LcSingle',
        components: {
          LcMenu: SingleView
        }
      },
      {
        path: '/success',
        name: 'LcSuccess',
        components: {
          LcMenu: SuccessView
        }
      },
      {
        path: '/upload',
        name: 'LcUpload',
        components: {
          LcMenu: UploadView
        }
      },
      {
        path: '/firstscan',
        name: 'first_scan',
        components: {LcMenu: FirstScan}
      },
      {
        path: '/firstCheck',
        name: 'first_check',
        components: {LcMenu: FirstCheck}
      },
      {
        path: '/profile',
        name: 'profile',
        components: {LcMenu: ProfileView}
      },
      {
        path: '/apruve-email',
        name: 'apruve-email',
        components: {LcMenu: ApruveEmailView}
      },
      {
        path: '/apruveaccount',
        name: 'apruveaccount',
        components: {LcMenu: ApruveView}
      },
    ]
  },
  
  {
    path: '/redirect',
    name: 'link_redirect',
    component: LinkAuthComponent
  },
  {
    path: '/recovery',
    name: 'recovery',
    component: RecoveryView
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
