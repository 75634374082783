<template>
    <div class="wrapper">
        <HeaderNav :auth="$store.state.isAuth"></HeaderNav>
        <v-form ref="form" lazy-validation >
            <MailView
                v-if="!registration && !logining && !recovery"
                v-bind:value=email
                v-bind:validate=validate
                v-bind:isLoading=isLoading
                @input="changeEmail"
            ></MailView>
            <PassLogin
                v-if="logining && !recovery"
                v-bind:value=password
                v-bind:login=login
                v-bind:password_rules="password_rules"
                v-bind:recovery=changeRecavery
                v-bind:isLoading=isLoading
                v-bind:drop=drop
                @input="changePass"
            ></PassLogin>
            <!-- <PassRegist
            v-if="registration && !recovery"
                v-bind:value=password
                v-bind:repeat=repeat
                v-bind:isLoading=isLoading
                v-bind:password_rules="password_rules"
                v-bind:repeat_rules="repeat_rules"
                v-bind:register=register
                @input="changePass"
                v-on:repeatpass="changeRepeat"
            ></PassRegist> -->
            <PassRegist
            v-if="registration && !recovery"
                v-bind:value=password
                v-bind:isLoading=isLoading
                v-bind:password_rules="password_rules"
                v-bind:register=register
                @input="changePass"
            ></PassRegist>
            <RecoveryPass
                v-if="recovery"
                v-bind:value=email
                v-bind:validate=sendRecovery
                v-bind:isLoading=isLoading
                v-bind:drop=drop
                v-bind:emailRulesProps=emailRules
                v-bind:sendrecoverymail="recovery_send"
                @input="changeEmail"
            ></RecoveryPass>
          
        </v-form>
    </div>
</template>

<script>
import MailView from '@/components/Auth/MailView.vue'
import HeaderNav from "@/components/HeaderNav.vue"
import jwt_decode from "jwt-decode"
import PassLogin from '@/components/Auth/PassLogin.vue';
import PassRegist from '@/components/Auth/PassRegist.vue';
import RecoveryPass from '@/components/Auth/RecoveryPass.vue';
import {mapActions} from 'vuex'

    export default {
    components: { HeaderNav, MailView, PassLogin, PassRegist, RecoveryPass },
    data() {
        return {
            email: "",
            password: "",
            // repeat: "",
            emailRules: [],
            valid_password: false,
            user_found: false,
            user_id: undefined,
            registration: false,
            logining: false,
            // repeat_rules: [],
            password_rules: [],
            recovery: false,
            recovery_send: false,
            isLoading: false,
        };
    },
    methods: {
        drop: function () {
            this.email = ""
            this.password = ""
            // this.repeat = ""
            this.emailRules = []
            this.valid = false
            this.valid_password = false
            this.user_found = false
            this.user_id = undefined
            this.registration = false
            this.logining = false
            // this.repeat_rules = []
            this.password_rules = []
            this.recovery = false,
            this.recovery_send = false,
            this.isLoading = false
        },
        sendRecovery() {
            this.isLoading = true
            console.log(this.email)
            this.$nextTick(() => {                
                    fetch(`${process.env.VUE_APP_MYHOST}/recovery`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            email: this.email,
                        })
                    })
                        .then(response => {
                        return response.json();
                    })
                        .then(data => {
                        if (data.data && data.data.id) {
                            this.recovery_send = true;
                            this.emailRules = [];
                        }
                        else {
                            this.emailRules = ["Данный email не зарегистрирован"];
                        }
                    });
              
            });
            this.isLoading = false
        },
        changeEmail(e){

            this.email = e.toLowerCase()
            this.emailRules = []
        },
        changePass(e){
            this.password = e
            this.password_rules = []
        },
        // changeRepeat(e){
        //     this.repeat = e
        //     this.repeat_rules = []
        // },
        changeRecavery(){
            this.recovery = !this.recovery
        },
        validate() {
            this.isLoading = true
            this.$nextTick(() => {
                    this.valid = true;
                    fetch(`${process.env.VUE_APP_MYHOST}/${this.email}`, {
                        method: "GET"
                    })
                        .then(response => {
                           
                        return response.json();
                        
                    })
                        .then(data => {
                        if (data.data && data.data.id) {
                            this.isLoading = false
                            this.logining = true;
                            this.user_found = true;
                            this.user_id = data.data.id;
                        }
                        else {
                            this.isLoading = false
                            this.registration = true;
                        }
                       
                    }); 
            });
            
           
        },
        register: function () {
            // if (this.password != this.repeat) {
            //     this.repeat_rules = ["Пароли не совпадают"];
            //     return;
            // }
            const pattern=/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
            const pattern1 = /^[?!,.a-zA-Z0-9\s]+$/
            
            if (this.password.length < 8) {
                this.password_rules = ["Пароль меньше 8 символов"];
                return;
            }
            if(!pattern1.test(this.password) || !pattern.test(this.password)){
                this.password_rules = ["Некорректный пароль"];
                return;
            }
            this.isLoading = true
            fetch(`${process.env.VUE_APP_MYHOST}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: this.email,
                    password: this.password,
                })
            })   
            .then(response => {
                return response.json();
            })
            .then(data => {
                    if (data.type == "error") {
                        this.isLoading = false
                       alert('ошибка, попробуйте еще!')              
                    }
                    else {
                        this.isLoading = false
                        this.$store.commit('isAcitvetedUser', false)
                        this.$store.commit('changeEmail', this.email)
                        this.$store.commit('changeIsAuth', true)
                        this.$cookies.set("cng_token", data.data.jwt);
                        this.$router.push({ name: "first_scan" });                    }
            });
        },
        login: async function () {
            if (this.password.length == 0 || this.password == '') {
                this.password_rules = ["Обязательное поле"];
                return;
            }
            this.isLoading = true
            fetch(`${process.env.VUE_APP_MYHOST}/validate`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: this.email,
                    password: this.password
                })
            })
                .then(response => {
                return response.json();
            })
                .then(data => {
                if (data.type == "error") {
                    this.isLoading = false
                         this.password_rules = ['неверный пароль']  
                }
                else {
                    if(data.message === 'ACCOUNT_NOT_ACTIVE'){
                        this.isLoading = false
                        this.$store.commit('isAcitvetedUser', false)
                        this.$store.commit('changeEmail', this.email)
                        this.$store.commit('changeIsAuth', true)
                        this.$cookies.set("cng_token", data.data.jwt);
                        this.$router.push({ name: "LcMain" })
                        // this.getQrcodes().then(res=> this.redurectAruveEmail(res))
                    } else {
                    this.isLoading = false
                    this.$store.commit('isAcitvetedUser', true)
                    this.$store.commit('changeEmail', this.email)
                    this.$store.commit('changeIsAuth', true)
                    this.$cookies.set("cng_token", data.data.jwt);
                    this.$router.push({ name: "LcMain" });
                   } 
                }
            });
        },
        redurectAruveEmail(qr){
            console.log(qr)
            if(this.$store.state.qrcodes && !this.$store.state.isActivetedUser){
                this.$router.push({name:'apruve-email'})
            } else {
                this.$router.push({ name: "LcMain" });
            }
           
        },
        ...mapActions({
            getQrcodes: 'getQrcodes'            
        })
    },
    mounted() {
        let token = this.$cookies.get("cng_token");
        if (token && jwt_decode(token).id) {
            this.$router.push({ name: "LcMain" });
        }
    },
}
</script>

<style lang="scss" scoped>
   .wrapper {
        width: 100%;
        //  margin: 10px !important;
        // padding: 0 !important;
        margin: 88px 0 50px 0 !important;
        padding: 0 16px !important;
    }

    .v-form h1{
        font-family: 'PT Sans';
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: -0.005em;
        text-align: center;

    }

    .v-form, 
    .form {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }
    
    label{
        background: transparent;
        background-color: transparent;
        display: none;
    }


    .v-form {
        background: #fff;
        width: 100%;
        padding: 32px 20px;
        border-radius: 16px;
    }
    .politic{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.015em;

        color: #868686;
    }


</style>