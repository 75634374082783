<template>
    <div class="wrapper">
        <HeaderNav :auth="$store.state.isAuth"></HeaderNav>
        <div class="v-form v-form__accept">
            <div class="alertActiveAccount" v-if="!this.$store.state.isActivetedUser">
                Подтведите email для&nbsp;участия&nbsp;в&nbsp;акции
            </div>
            <div class="accept__sub">
                <h1>Чек принят на рассмотрение</h1>
                <p  v-if="!this.$store.state.isActivetedUser">Письмо с инструкцией отправлено на {{ this.$store.state.email }}</p>
                <p  v-else>Проверка чека происходит в&nbsp;течение 24&nbsp;часов. Сохраняйте чек до&nbsp;окончания акции.</p>
            </div>
            <div class="accept__btns">
                <v-btn
                    depressed
                    color="primary"
                    class="next-btn btn__download"
                    block
                    @click="$router.push({name:'LcMain'})"
                    >
                    <div class='btn-content-container'>
                        <img src="@/assets/loader.svg" alt="loader" class="loader-icon"  :class="{'hidden': !isLoading}"/>
                        Мои чеки
                    </div>
                </v-btn>
                <v-btn
                    depressed
                    color="primary"
                    class="next-btn accept__btn"
                    block
                    @click="$router.push({name:'LcScan'})"
                    >
                    <div class='btn-content-container'>
                        <img src="@/assets/loader.svg" alt="loader" class="loader-icon"  :class="{'hidden': !isLoading}"/>
                        Сканировать новый чек
                    </div>
                </v-btn>
                <div class="container-btnLink" v-if="!this.$store.state.isActivetedUser">
                    <span class="forgotPassword" @click="$router.push({name:'apruve-email'})">Подтвердить email</span>
                </div>
            </div>      
        </div>
    </div>
</template>
<script>
import HeaderNav from '@/components/HeaderNav.vue';
import {mapActions} from 'vuex'

export default {
    data(){
            return{
                isLoading: false
            }
    },
    methods:{
        ...mapActions({
            getUserData: 'getUserData'
        })
    },
    mounted(){
        // this.getUserData()
    }, 
    components: { HeaderNav }
}
</script>

<style lang="scss" scoped>
   .wrapper {
        width: 100%;
        margin: 0px 0 0 0 !important;
        padding: 0  !important;
    }
    .v-form, 
    .form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 100%;
    justify-content: flex-start;
    // min-width: none;
}
    .alertActiveAccount{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;

    width:100%;
    min-height: 60px !important;
    background: #FDE4E7;
    border-radius: 6px;
    flex: none;
    order: 0;
    flex-grow: 0;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.015em;
    color: #D3626F;
    margin-bottom: 28px;
}
    .v-form h1{
        font-family: 'PT Sans';
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: -0.005em;
        text-align: center;
        margin-bottom: 18px;
    }
    .title__wrap {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 32px;
    }
    .btn-content-container{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .loader-icon{
        margin-right: 10px;
        visibility: visible;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    .loader-icon.hidden{
       visibility: hidden;
    }
    .accept__btns{
        min-height: 44px;
    }
    .accept__sub{
        gap: 0;
        margin-bottom: 44px;
    }

    .v-form, 
    .form {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .v-form {
        background: #fff;
        width: 100%;
        padding: 32px 20px;
        border-radius: 16px;
    }
    .next-btn {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 12px;
    
    }
    button.next-btn.btn__scan.v-btn.v-btn--block.v-btn--has-bg.theme--light.v-size--default.primary {
    width: 100% !important;
    background-color: #F77988 !important;
    height: 44px;
    margin-top: -14px;
}
    .forgotPassword{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.015em;

        color: #4F70E4;
    }
    .politic{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.015em;

        color: #868686;
    }

    .container-btnLink{
        display: flex;
        flex-direction: column;
    }
    .forgotPassword{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.015em;

        color: #4F70E4;
        margin-bottom: 5px;
        cursor: pointer;
    }
</style>