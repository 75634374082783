<template>
    <div class="popup-container" :class="{'hidden': hidden}" :style="{top: `${top}px`}">
        <div class="popup-container-content" @touchmove.prevent="touchMoved" @touchstart.stop="touchStart" @touchend="touchCansel">
            <span class="popup-container-content__text">У вас новое уведомление!</span>
            <span class="popup-container-content__btn" @click.stop="openNotification">Открыть</span>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
    export default {
        data(){
            return{
                start_height: 0,
                current_height: 0,
                finish_height: 0,
                top: 0,
                hidden: false
            }
        },
        methods:{
            ...mapActions({
            changeStatusNotification: "changeStatusNotification",
            }),
            touchStart(e){
                this.start_height = e.touches[0].clientY
                this.current_height = e.touches[0].clientY
                
                
            },
            touchMoved(e){
                let height 
                if(e.touches[0].clientY > this.start_height){
                    height = this.start_height
                } else {
                    height = e.touches[0].clientY
                }
                
                this.current_height = e.touches[0].clientY
                this.top = height - this.start_height
                
            },
            touchCansel(){
                if(this.start_height - this.current_height > 25){
                    this.hidden = true
                    this.top = -80
                    setTimeout(() => {
                        this.$store.commit('changeVisiblePopupNotification',false)
                    }, 500)
                
                } else {
                    this.top = 0
                }
            },
            openNotification(){
                console.log(this.$store.state.firstNoReadenNotification)
                const id = this.$store.state.firstNoReadenNotification
                this.$router.push({name:'NotificationSingle', params:{id: id}})
                this.$store.commit('changeVisiblePopupNotification',false)
                this.changeStatusNotification(id)
            }
        }, 
        watch:{
            '$store.state.isVisiblePopupNotification': function(){
                if(this.$store.state.isVisiblePopupNotification){
                    this.hidden = !this.$store.state.isVisiblePopupNotification
                    this.top=0
                }
                
            },
        },
        mounted(){

        }
    }
</script>

<style lang="scss" scoped>
.popup-container{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 71px;
    z-index: 101;
    opacity: 1;
    transition: all 0.05s linear;
}
.popup-container.hidden{
    opacity: 0.2;
    transition: all 0.3s linear;
}
.popup-container-content{
    display: flex;  
    align-items: center;
    justify-content: center;
    min-width: 328px;
    width: 100%;
    padding: 25px 19px;
    margin: 0 16px;
    height: 71px;
    background: #FFFFFF;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #798DF7;
    border-radius: 0px 0px 16px 16px;
}
.popup-container-content__text{
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: -0.005em;

    color: #1A1A1A;
}
.popup-container-content__btn{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.015em;
    margin-left: 10px;
    padding: 5px 10px;
    color: #0072BC;
    cursor: pointer;
}
</style>