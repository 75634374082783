<template>
    <div>
        <HeaderNav :auth="$store.state.isAuth"></HeaderNav>
        <div class="wrapper" v-if="!isRecoveryPass">
            <v-form ref="form" lazy-validation>
                <div class="recovery-title">
                    <h1>Введите новый пароль</h1>
                </div>
                <v-text-field
                    :append-icon="showIconHiddenPass ? 'mdi-eye' : 'mdi-eye-off'"
                    v-model="password"
                    label="Пароль"
                    dense
                    :rules="password_rules"
                    outlined
                    autofocus
                    class="password-field"
                    :class="{'error-input': this.password_rules.length > 0, 'true-input': this.password_rules.length === 0 && passTrue}"
                    :type="showIconHiddenPass ? 'text' : 'password'"
                    required
                    @click:append="showIconHiddenPass = !showIconHiddenPass"
                ></v-text-field>
                <!-- <v-text-field
                    :append-icon="showIconHiddenRepeat ? 'mdi-eye' : 'mdi-eye-off'"
                    v-model="repeat"
                    placeholder="Repeat"
                    dense
                    outlined
                    class="password-field"
                    :type="showIconHiddenRepeat ? 'text' : 'password'"
                    required
                    clearable
                    :rules="repeat_rules" 
                    @input="repeat_rules=[]"
                    @click:append="showIconHiddenRepeat = !showIconHiddenRepeat"
                >
                </v-text-field> -->
                <v-btn
                depressed
                :color="(password && repeat)?'primary':''"
                class="next-btn"
                block
                @click="doRecovery"
                >Сохранить</v-btn>
                <p class="politic">Пароль должен состоять из&nbsp;восьми или&nbsp;более символов латинского алфавита, содержать заглавные&nbsp;и&nbsp;строчные буквы, цифры</p>
            </v-form>
        </div>
        <div class="wrapper" v-if="isRecoveryPass">
            <v-form ref="form" lazy-validation>
                <div>
                    <h1 class="recovery__title">Пароль успешно сохранён</h1>
                </div>
                <v-btn
                depressed
                :color="'primary'"
                class="next-btn"
                block
                @click="$router.push({name:'login'})"
                ><div class='btn-content-container'>
                    <img src="@/assets/loader.svg" alt="loader" class="loader-icon"  :class="{'hidden': !isLoading}"/>
                    Войти
                </div></v-btn>
            </v-form>
        </div>
    </div>
</template>
<script>
import HeaderNav from "@/components/HeaderNav.vue"
import jwt_decode from "jwt-decode"
export default {
    data() {
        return {
            password: "",
            repeat: "",
            repeat_rules: [],
            password_rules: [],
            user_id: "",
            isRecoveryPass: false,
            showIconHiddenPass: false,
            showIconHiddenRepeat: false,
            passTrue: false
        };
    },
    methods: {
        doRecovery() {
            // if (this.password != this.repeat) {
            //     this.repeat_rules = ["Пароли не совпадают"];
            //     return;
            // }
            const pattern=/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
            const pattern1 = /^[?!,.a-zA-Z0-9\s]+$/
            
            if (this.password.length < 8) {
                this.password_rules = ["Пароль меньше 8 символов"];
                return;
            }
            if(!pattern1.test(this.password) || !pattern.test(this.password)){
                this.password_rules = ["Некорректный пароль"];
                return;
            }
            
            this.$nextTick(() => {
                if (this.$refs.form.validate()) {
                    fetch(`${process.env.VUE_APP_ACCOUNTS}/${this.user_id}`, {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            password: this.password,
                        })
                    })
                        .then(response => {
                        return response.json();
                    })
                        .then(data => {
                        if (data.data) {
                            this.isRecoveryPass = true
                            // this.$router.push({ name: "login" });
                        }
                        else {
                            this.password_rules = ["Произошла ошибка. Попробуйте позже"];
                        }
                    });
                }
            });
        },
    },
    watch: {
        password(){
            const pattern=/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
            const pattern1 = /^[?!,.a-zA-Z0-9\s]+$/
            
            if (this.password.length < 8) {
                this.passTrue = false;
            }else if(!pattern1.test(this.password) || !pattern.test(this.password)){
                this.passTrue = false;
            } else {
                this.passTrue = true;
            }
            this.password_rules = []
        },
        repeat(){
            this.repeat_rules = []
        },       
    },
    mounted() {
        if (!this.$route.query.token) {
            this.$router.push({ name: "login" });
            return;
        }
        let id = jwt_decode(this.$route.query.token)["id"];
        if (!id) {
            this.$router.push({ name: "login" });
            return;
        }
        fetch(`${process.env.VUE_APP_ACCOUNTS}/${id}`, {
            method: "GET"
        })
            .then(response => {
            return response.json();
        })
            .then(data => {
            if (!data.data) {
                this.$router.push({ name: "login" });
                return;
            }
            this.user_id = id;
        });
    },
    components: { HeaderNav }
}
</script>
<style scoped>
p.note {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 130% !important;
}
.recovery {
    margin-top: 12px;
    color: #044EF3;
    font-size: 12px !important;
    cursor: pointer;
}
.wrapper {
    width: 100%;
    border-radius: 16px;
    padding: 61.5px 22.5px;
    margin: 128px auto;
}
.wrapper h1{
    font-family: 'PT Sans';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 100%;
/* identical to box height, or 20px */

text-align: center;
letter-spacing: -0.005em;

color: #1A1A1A;
}
.recovery__title{
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    /* identical to box height, or 20px */

    text-align: center;
    letter-spacing: -0.005em;

    color: #1A1A1A;
    margin-bottom: 24px;
}
.recovery-title{
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.005em;

    color: #1A1A1A;
    margin-bottom: 50px;
}

.next-btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}
.loader-icon{
        margin-right: 10px;
        visibility: visible;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    .loader-icon.hidden{
       visibility: hidden;
    }
.politic{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.015em;

        color: #868686;
        margin-top: 12px;
    }
</style>
