<template>
    <div class="v-form v-form__downl">
        <h1 class="upload__title">Не удалось отсканировать QR&#8209;код?</h1>
        <p class="upload__text">Загрузите фото чека или&nbsp;введите&nbsp;данные&nbsp;вручную</p>
        <div class="accept__btns">
            <div>
                <input  type="file" id="qr" accept=".png,.jpg,.jpeg" capture="file" ref="uploader" @change="onFileChanged">
                <v-btn
                depressed
                color="primary"
                class="next-btn btn__download accept__btn"
                block
                :loading="isSelecting"
                @click="handleFileImport"
                >
                <div class='btn-content-container'>
                    <img src="@/assets/loader.svg" alt="loader" class="loader-icon"  :class="{'hidden': !isLoading}"/>
                    Загрузить фото чека
                </div></v-btn>
            </div>

            <div>
                <v-btn
                depressed
                color="primary"
                class="next-btn"
                block
                @click="$router.push({name:'LcManual'})"
                ><div class='btn-content-container'>
                    <img src="@/assets/loader.svg" alt="loader" class="loader-icon"  :class="{'hidden': !isLoading}"/>
                    Ввести вручную
                </div></v-btn>
            </div>
        </div>
    </div>
</template>
<script>
import jwt_decode from 'jwt-decode'
import {mapActions} from 'vuex'
import moment from 'moment'
export default {
    data(){
        return {
            isSelecting: false,
            selectedFile: null,
            isLoading: false,
            user_id: jwt_decode(this.$cookies.get("cng_token"))["id"]
        }
    },
    methods: {
        startDemo(file){
            this.isLoading = true
            const currentDate = moment().utc().format("YYYYMMDDTHHmm")
 
            // const data = {
            //     account_id: this.user_id,
            //     summary: 696,
            //     time: currentDate,
            //     fn: 69696,
            //     fd: 234456,
            //     fp: 123435,
            //     type: 'moderate',
            //     file: file
            // }
            // console.log(data)
            let formData = new FormData();
                formData.append("file", file);
                formData.append("account_id", this.user_id,);
                formData.append("summary", 696);
                formData.append("time", currentDate);
                formData.append("fn", 234456);
                formData.append("fd", 234456);
                formData.append("fp", 123435);
                formData.append("type", 'moderate');
            fetch(`${process.env.VUE_APP_NT}/demo`, {
                    method: "POST",
                    // headers: {
                    //     "Content-Type": "multipart/form-data"
                    // },
                    body: formData,
                }).then(response => {
                    console.log(response)
                    if(response.status == 400 || response.status == 500){
                            alert('Error')
                            this.isLoading = false
                            return undefined
                     } else {
                        return response.json();
                     }
                        
                    })
                    .then(data => {
                        console.log(data)
                        if(data){
                            if(data.data){
                                this.getUserNotification()
                                this.isLoading = false
                                this.$router.push('/success')
                            } else {
                                this.isLoading = false
                                this.$router.push('/success')
                            } 
                        }
                           
                        
                });
        },   
        handleFileImport() {
            this.isSelecting = true;
            window.addEventListener("focus", () => {
                this.isSelecting = false;
            }, true);
            this.$refs.uploader.click();
            setTimeout(()=> {
                this.isSelecting = false;
            }, 1000)                        //TODO решение не нашел, вернутся что бы испрвить
        },
        onFileChanged(e) {
            this.selectedFile = e.target.files[0];
            console.log(e.target.files)
            if(process.env.VUE_APP_ENV_PROJECT === 'demo'){
                this.startDemo(this.selectedFile)
            } else {
                let formData = new FormData();
                formData.append("file", this.selectedFile);
                formData.append("account_id", this.user_id);
                formData.append("type", 'moderate');
                fetch(`${process.env.VUE_APP_QR}`, {
                    method: "POST",
                    body: formData
                })
                    .then(response => {
                        if(response.status == 400){
                            console.log(response)
                            alert('Only png jpg jpeg files')
                        }
                        else if(response.ok){
                            if(this.$store.state.isActivetedUser){
                                this.$router.push({name:'LcSuccess'})
                            } else {
                                this.$router.push({name:'first_check'})
                            }
                            
                        }
                    return response.json();
                });
            }
        },
        decodeStr(str) {
            console.log(str);
            try {
                let kv = str.split("&");
                let res = {};
                for (let i = 0; i < kv.length; i++) {
                    let tmp = kv[i].split("=");
                    res[tmp[0]] = tmp[1];
                }
                return res;
            }
            catch (e) {
                console.log(e);
                return false;
            }
        },
        ...mapActions({
            getUserNotification: "getUserNotification",           
        })
    },
    
}
</script>
<style scoped>
.v-form.v-form__downl{
    gap:24px; 
    min-height: 200px !important;
}

.upload__title{
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #1A1A1A;
    margin-bottom: 18px;
}

.upload__text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.015em;
    color: #1A1A1A;
    margin-bottom: 28px !important;
}

.accept__btns{
    gap: 0 !important;
}

.next-btn.btn__download.accept__btn{
    margin-bottom: 4px;
}
/* .v-form.v-form__downl h1{  
font-family: 'PT Sans';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 110%;
text-align: center;
letter-spacing: -0.005em;
color: #1A1A1A;
} 
.v-form.v-form__downl p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.015em;

    color: #1A1A1A;
} */
</style>