<template>
    <div class="v-form v-form__check check" v-if="current_qr">
        <div class="check-title">
            <!-- Чек №{{current_qr.id}} -->
            Чек №0000123456789012
        </div>
       
        <div class="check__wrap">
            <div class="check__content">
                <p class="check__subtitle">Дата загрузки</p>
                <v-text-field
                    class="check-input__disabled"
                    hide-details
                    outlined
                    :value="getDate(current_qr.create_date)"
                    dense
                    disabled
                ></v-text-field>
            </div>
            <div class="check__content">
                <p class="check__subtitle">Время загрузки</p>
                <v-text-field
                    outlined
                    class="check-input__disabled"
                    :value="getTime(current_qr.create_date)"
                    dense
                    disabled
                ></v-text-field>
            </div>
            <div class="check__content">
                <p class="check__subtitle">Сумма</p>
                <v-text-field
                    outlined
                    class="check-input__disabled"
                    :value="current_qr.summary?current_qr.summary:''"
                    dense
                    disabled
                ></v-text-field>
            </div>
            <div class="check__content">
                <p class="check__subtitle">ФД - номер</p>
                <v-text-field
                    outlined
                    class="check-input__disabled"
                    :value="current_qr.fd?current_qr.fd:''"
                    dense
                    disabled
                ></v-text-field>
            </div>
            <div class="check__content">
                <p class="check__subtitle">Статус проверки</p>
                <v-text-field
                    outlined
                    class="check-input__disabled"
                    :class="{'verified': current_qr.status === 'verified', 'wait': current_qr.status === 'wait', 'rejected': current_qr.status === 'not verified'}"  
                    :value="statuses[current_qr.status]"
                    dense
                    disabled
                ></v-text-field>
            </div>
        </div>
        <v-btn
            depressed
            color="primary"
            class="next-btn check-btn"
            block
            @click="$router.push({name:'LcScan'})"
            >  <div class='btn-content-container'>
                    <img src="@/assets/loader.svg" alt="loader" class="loader-icon"  :class="{'hidden': !isLoading}"/>
                    Сканировать новый чек
            </div></v-btn>
        <p class="recovery" @click="$router.push({name:'LcMain'})">Вернуться к&nbsp;списку чеков</p>
    </div>
</template>
<script>
import moment from 'moment'
export default {
 
    data(){
        return {
            current_qr: undefined,
            isLoading: false,
            statuses: {
                "wait": "Проверка",
                "verified": "Принято",
                "not verified": "Отклонено",   
            },
        }
    },
    methods:{
        getTime(fullDate){
            let utcZone = moment.utc()
            let CurrentZone = moment.utc()
            CurrentZone.local()
            let resultUTC = CurrentZone.hours() - utcZone.hours()
            const date = new Date(moment(fullDate, "YYYY-MM-DDTHH:mm").format("YYYY-MM-DDTHH:mm"))   
            return moment(+date + 1000 * 60*60 *resultUTC).format("HH:mm (UTC+3)")
        },
        getDate(fullDate){
            let utcZone = moment.utc()
            let CurrentZone = moment.utc()
            CurrentZone.local()
            let resultUTC = CurrentZone.hours() - utcZone.hours()
            const date = new Date(moment(fullDate, "YYYY-MM-DDTHH:mm").format("YYYY-MM-DDTHH:mm"))
            return moment(+date + 1000 * 60*60 *resultUTC).format("DD.MM.YYYY")
        }
    },
    mounted(){
        fetch(`${process.env.VUE_APP_QR}/${this.$route.params.id}`, {
            method: "GET"
        })
            .then(response => {
            return response.json();
        })
            .then(data => {
            this.current_qr = data.data;
        });
    }
}
</script>

<style scoped>
    .v-form.v-form__check.check{
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.005em;

        color: #1A1A1A;
     
    }
    .check__wrap{
        gap: 18px;
    }
    .check-title{
        margin-bottom: 32px;
    }
    .check__subtitle{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: -0.015em;
        color: #1A1A1A;
        margin-bottom: 8px !important;
    }

    .check-input__disabled {
        background-color: #F1F1F1 !important;
        background: #F6F6F6;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: -0.015em;

        color: #1A1A1A !important;
        border-radius: 10px;
        height: 43px;
    }
    .check-input__disabled.wait{
        background-color: #F5DFCC!important;
    }
    .check-input__disabled.verified{
        background-color: #DEECDC!important;
    }
    .check-input__disabled.rejected{
        background-color: #FAE3DE!important;
    }
    .btn-content-container{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.015em;

        color: #FFFFFF;
    }
    .check-btn{
        margin-bottom: 12px;
    }
    .loader-icon{
        margin-right: 10px;
        visibility: visible;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    .recovery{
        padding: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.015em;
        color: #4F70E4;
        margin-top: 0 !important;
    }
    .loader-icon.hidden{
       visibility: hidden;
    }

</style>
