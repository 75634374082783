<template>
    <div class="wrapper">
        <HeaderNav :auth="$store.state.isAuth"></HeaderNav>
            <v-form ref="form" lazy-validation >
                <div class="title__wrap">
                    <h1>Подтвердите email</h1>
                    <p>Для завершения регистрации перейдите по&nbsp;ссылке, отправленной на&nbsp;указанный email</p>
                </div>
                <div class="inputs">
                    <p class="check__subtitle">Email</p>
                    <v-text-field
                    v-model="this.$store.state.email"
                    placeholder="Email"
                    disabled
                    dense
              
                    outlined
                    class="check-input__disabled"
                    required
                    clearable
                >
                </v-text-field>
                </div>
                <v-btn
                    depressed
                    :color="'primary'"
                    class="next-btn"
                    block
                    @click="sendMessageEmail"
                    >
                    <div class='btn-content-container'>
                    <img src="@/assets/loader.svg" alt="loader" class="loader-icon"  :class="{'hidden': !isLoading}"/>
                        Отправить ссылку
                    </div></v-btn>
            </v-form>

    </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue";
import jwt_decode from "jwt-decode"

    export default {
    props: {},
    data() {
        return {
            user_id: jwt_decode(this.$cookies.get("cng_token"))["id"],
            isLoading: false
            
        };
    },
    methods: {
        sendMessageEmail(){
            this.isLoading = true
            fetch(`${process.env.VUE_APP_MYHOST}/sendemail`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: this.$store.state.email,
                })
            })   
            .then(response => {
                return response.json();
            })
            .then(data => {
                    if (data.type == "error") {
                        this.isLoading = false
                       alert('ошибка, попробуйте еще!')              
                    }
                    else {
                        this.isLoading = false
                        this.$router.push({ name: "LcMain" });  
                    }
            });
        }
    },
    mounted() {
       
    },
    components: { HeaderNav }
}

</script>

<style lang="scss" scoped>
   .wrapper {
        width: 100%;
        margin:  0 !important;
        padding: 0 !important;
    }
    .title__wrap {
        display: flex;
        flex-direction: column;
        gap: 18px;
        margin-bottom: 32px;
    }
    .title__wrap h1{
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.005em;

        color: #1A1A1A;
    }

    .title__wrap p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.015em;
        color: #1A1A1A;
    }

    .v-form h1{
        font-family: 'PT Sans';
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: -0.005em;
        text-align: center;

    }

    .v-form, 
    .form {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .v-form {
        background: #fff;
        width: 100%;
        padding: 32px 20px;
        border-radius: 16px;
    }
    .inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
    gap: 4px ;
    }
    .check-input__disabled{
        background-color: #F1F1F1 !important;
        height: 44px;
        border-radius: 10px;
    }
    .check__subtitle{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.015em;
        color: #999999;
    }
    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot{
        margin-bottom: 6px !important;
    }

    .next-btn {
        font-weight: 700;
        font-size: 16px;
        height: 44px !important;
        border-radius: 12px !important;
        line-height: 24px;
        margin-bottom: 12px;
        margin: 0 !important;
    }
    .politic{
        font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.015em;

    color: #868686;
    }
    .politic a{
        color: #868686;
    }
</style>