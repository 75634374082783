<template>
    <div>
        <HeaderNav :auth="$store.state.isAuth"></HeaderNav>
        <div class="wrapper">
            <div class='container'>
                <div class="notification-backBtn" @click="pushBack">
                    Назад
                </div>
                <div class="notification-image">
                    <img  src="@/assets/img/Notification_img_1.png" alt="изображение акции" />
                </div>
                <div class="notification-title">
                    {{ promo_item?.title_message }}
                </div>
                <div class="notification-text" v-html="promo_item?.text_message">
                  
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue';

export default {
    components: { HeaderNav },
    data(){
            return{
                promo_item: {
                    title_message: 'Подробнее об акции:',
                    text_message: `Акция действует с&nbsp;[дата начала] по [дата окончания] включительно.
                                    <br> <br>
                                    Участие в&nbsp;акции открыто для всех физических лиц, достигших возраста 18&nbsp;лет.
                                    <br> <br>
                                    Для участия в&nbsp;акции необходимо зарегистрировать чек(и) на&nbsp;нашем сайте [указать адрес сайта].
                                    <br> <br>
                                    Один чек может быть зарегистрирован только один раз. В&nbsp;случае, если чек был зарегистрирован повторно, участник будет дисквалифицирован.
                                    <br> <br>
                                    Каждый зарегистрированный чек будет участвовать в&nbsp;розыгрыше призов.
                                    <br> <br>
                                    Количество призов ограничено. Результаты розыгрыша будут опубликованы на&nbsp;нашем сайте в&nbsp;течение [указать срок].
                                    <br> <br>
                                    Призы не могут быть обменены на&nbsp;деньги или&nbsp;другие товары.
                                    <br> <br>
                                    Организаторы акции не&nbsp;несут ответственности за&nbsp;ошибки в&nbsp;регистрации чеков, вызванные техническими проблемами на&nbsp;стороне участника или&nbsp;нашего сайта.
                                    <br> <br>
                                    Организаторы акции имеют право дисквалифицировать участников, нарушивших правила акции.
                                    <br> <br>
                                    Участники акции соглашаются с&nbsp;тем, что организаторы акции могут использовать их&nbsp;имя, фотографию и&nbsp;город проживания в&nbsp;качестве победителей для целей рекламы и&nbsp;маркетинга.
                                    <br> <br>
                                    Все&nbsp;споры, возникающие в связи с&nbsp;данной акцией, будут разрешаться в&nbsp;соответствии с&nbsp;законодательством [указать страну или регион]`
                },
                auth: true
            }
        },
        methods:{
            isAuth(){
                if(this.$store.state.email == ''){
                    this.auth = false
                } else {
                    this.auth = true
                }

            },
            pushBack(){
                const path = sessionStorage.getItem('lastPath')
                if(path){
                    sessionStorage.removeItem('lastPath')
                    this.$router.push({name: path})
                } else {
                    this.$router.push({name: 'Start'})
                }
               
            },
            async scrollToTop() {
                window.scrollTo(0,0);
            }
        },
        mounted(){
            this.scrollToTop()
            this.isAuth()
        }

}
</script>

<style lang="scss" scoped>
    .container{
        padding: 20px 20px 32px 20px;
    }
    .notification-backBtn{
        text-align: start;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: -0.015em;
        color: #4F70E4;
        cursor: pointer;
        margin-bottom: 28px;
    }
    .notification-image{
        width: 100%;
        margin-bottom: 18px;
    }
    .notification-image img{
        object-fit: contain;
        width: 100%;
    }
    .notification-title{
        text-align: start;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.015em;

        color: #1A1A1A;
        margin-bottom: 16px;
    }
    .notification-text{
        text-align: start;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.015em;

        color: #1A1A1A;
    }
</style>