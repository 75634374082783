<template>
    <div>
        <HeaderNav :auth="false"></HeaderNav>
    
        <div class="wrapper">
            <div class='container'>
            Поддержка
            </div>
        </div>
    </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue';

    export default {
    components: { HeaderNav }
}
</script>

<style lang="scss" scoped>

</style>