<template>
    <div>
        <HeaderNav :auth="$store.state.isAuth"></HeaderNav>
        <div class="wrapper">
            <div class='container'>
                <div class="notification-backBtn" @click="$router.push({name:'profile'})">
                    Назад
                </div>
                <div class="notification-image">
                    <img  src="@/assets/img/Notification_img_1.png" alt="изображение акции" />
                </div>
                <div class="notification-title">
                    {{ this.promo_item?.title_message }}
                </div>
                <div class="notification-text" v-html="this.promo_item?.text_message">
              
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue';

export default {
    components: { HeaderNav },
    data(){
            return{
                promo_item: {
                    title_message: 'Правила розыгрыша:',
                    text_message: `<ol>
                                        <li>Необходимо купить N количество продуктов;</li>
                                        <li>Отсканировать все чеки, что у&nbsp;вас есть;</li>
                                        <li>Храните чеки до получения призов.</li>
                                    </ol>`
                },

            }
        },

}
</script>

<style lang="scss" scoped>
    .container{
        padding: 20px 20px 32px 20px;
    }
    .notification-backBtn{
        text-align: start;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: -0.015em;
        color: #4F70E4;
        cursor: pointer;
        margin-bottom: 28px;
    }
    .notification-image{
        width: 100%;
        margin-bottom: 18px;
    }
    .notification-image img{
        object-fit: contain;
        width: 100%;
    }
    .notification-title{
        text-align: start;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.015em;

        color: #1A1A1A;
        margin-bottom: 16px;
    }
    .notification-text{
        text-align: start;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.015em;

        color: #1A1A1A;
    }
</style>