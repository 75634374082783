<template>
    <div>
        <HeaderNav :auth="$store.state.isAuth"></HeaderNav>
    
        <div class="wrapper">
            <div class='container'>
                <div class="notification-title">
                    Уведомления
                </div>
                <NotificationList :notifications="this.$store.state.notificationList"></NotificationList>
            </div>
    </div>
    </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue';
import NotificationList from '@/components/NotificationList.vue';
import {mapActions} from 'vuex'

    export default {
    components: { HeaderNav, NotificationList },
    methods:{
        ...mapActions({
            getUserNotification: "getUserNotification",
        }),
    },
    mounted(){
        // this.getUserNotification();
    }
}
</script>

<style lang="scss" scoped>
    .container{
        min-width: 328px;
    }
    .notification-title{
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.005em;

        color: #1A1A1A;
        margin-bottom: 32px;
    }
</style>