<template>
    <div>
            <div class="title__wrap">
                <h1 v-if="this.sendrecoverymail">Ссылка для восстановления пароля отправлена на ваш email</h1>
                <h1 v-else>Забыли пароль?</h1>
            </div>
            <form @submit.prevent="validateEmail">
                <div class="inputs" v-if="!this.sendrecoverymail">
                    <v-text-field
                    v-model="valueEmail"
                    label="Email"
                    :rules="emailRules"
                    @input="updateInput"
                    dense
                    outlined
                    class="email-field"
                    autocomplete="email"
                    :class="{'error-input': this.emailRules.length > 0, 'true-input': this.emailRules.length == 0 && emailTrue}"
                    required
                    clearable
                >
                </v-text-field>
                </div>
                <v-btn
                    v-if="this.sendrecoverymail"
                    depressed
                    :color="'primary'"
                    class="next-btn"
                    block
                    @click="drop"
                    >Войти</v-btn>
                <v-btn
                    v-else
                    depressed
                    :color="'primary'"
                    class="next-btn"
                    block
                    @click="validateEmail"
                    ><div class='btn-content-container'>
                        <img src="@/assets/loader.svg" alt="loader" class="loader-icon"  :class="{'hidden': !isLoading}"/>
                
                        Далее
                    </div></v-btn>
            </form>
            <p v-if="!this.sendrecoverymail" class="politic">Мы отправим вам ссылку для восстановления пароля на указанный вами адрес</p>

    </div>
</template>

<script>

    export default {
        props: {
            value: {type: String, required: true},
            isLoading: {type: Boolean, required: true},
            sendrecoverymail: {type: Boolean, required: true},
            validate: {type: Function, required: true},
            drop: {type: Function, required: true},
            emailRulesProps: {type: Array},
        },
        data(){
            return{
                valueEmail: this.value,
                emailRules: this.emailRulesProps,
                prevValue: '',
                emailTrue: true
            }
        },
        methods: {
            validateEmail(){
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                console.log(this.valueEmail)
                if(pattern.test(this.valueEmail)){
                    this.emailRules = this.emailRulesProps
                    this.validate()
                } else {
                    this.emailRules = ['проверьте email']
                }
            },
            async updateInput(event) {
                if(event.split('').includes(' ')){
                    await this.$nextTick()
                    this.valueEmail = this.prevValue
                    this.emailRules = []
                } else {
                    await this.$nextTick()
                    this.prevValue = this.valueEmail
                    await this.$nextTick()
                    this.valueEmail = event
                    await this.$nextTick()
                    this.$emit('input', event) 
                    this.emailRules = []
                }
            }
        },
        watch:{
            valueEmail(){
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                if(pattern.test(this.valueEmail)){
                    this.emailTrue = true
                } else {
                    this.emailTrue = false
}
            },
            emailRulesProps(){
                this.emailRules = this.emailRulesProps
            }
        }
        
}
</script>

<style lang="scss" scoped>
   .wrapper {
        width: 100%;
        margin: 88px 0 50px 0 !important;
        padding: 0 16px !important;
    }
    .title__wrap {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 32px;
    }

    .v-form h1{
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.005em;

        color: #1A1A1A;

    }
    .btn-content-container{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .loader-icon{
        margin-right: 10px;
        visibility: visible;
    }
    .loader-icon.hidden{
       visibility: hidden;
    }

    .v-form, 
    .form {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .v-form {
        background: #fff;
        width: 100%;
        padding: 32px 20px;
        border-radius: 16px;
    }
    .inputs {
    display: flex;
    flex-direction: column;
    // margin-bottom: 28px;
    gap: 20px;
    }
    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot{
        margin-bottom: 6px !important;
    }

    .next-btn {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        margin-top: 2px;
        margin-bottom: 12px;
    }
    .politic{
        font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.015em;

    color: #868686;
    }
    .politic a{
        color: #868686;
    }
</style>