<template>
    <div class="wrapper-menu">
        <div class="check-titleBlock">
            <h1 class="check-titleBlock__title">Мои чеки</h1>
            <div class="check-title__counter">
                 {{ checkList.length }} чеков
            </div>
        </div>
        <div 
        class="menu-container"
        v-for="item in menus"
        :key="item.id"
        @click="pushInPromo(item.link)"
        >
            <div class="profile-menu__text">
                {{item.text}}
            </div>
            <div class="profile-menu__arrow">
                
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            checkList: {type: Array, required: true}
        },
        data(){
            return{
                menus:[{text: 'Загруженные чеки', link: 'LcMain'},
                {text: 'Подробнее об акции', link: 'TermsPromo'},
                {text: 'Правила розыгрыша', link: 'RulesDraw'},],
            }
        },
        methods:{
            pushInPromo(link){
                if(link === 'TermsPromo'){
                    sessionStorage.setItem('lastPath', 'profile') 
                }
               
                this.$router.push({name:link})
            },
        }
    }
</script>

<style lang="scss" scoped>
    .wrapper-menu {
        // min-height: 320px;
        margin-top: 0 !important;
        gap: 12px;
        margin-bottom: 8px;
    }
    .check-titleBlock{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 28px;
    }
    .check-titleBlock__title{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: -0.005em;

        color: #1A1A1A;

    }
    .check-title__counter{
        min-width: 92px;
        height: 26px;
        text-align: center;
        padding: 10px;
        background: #FA73A4;
        border-radius: 15px;
    }
    .check-title__counter{
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 130%;
        letter-spacing: -0.005em;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .menu-container{
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 44px;
        background: #F1F1F1;
        border-radius: 10px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: -0.015em;
        color: #1A1A1A;
        margin-bottom: 12px;
        padding: 15px 8px;
        cursor: pointer;
    }
    .check__date{
        width: 50%;
        min-width: 121px;
        padding-right: 12px;
    }
    .check__status{
        font-size: 12px;
        background: #F5DFCC;
        border-radius: 5px;
        width: 40%;
        padding: 5px 17px;
        min-width: 75px;
    }
    .check__status.verified{
        background: #DEECDC;
    }
    .check__status.rejected{
        background: #FAE3DE;
    }
    .profile-menu__arrow{
        // width: 10%;
        position: absolute;
        right:14px;
        min-width: 10px;
        height: 10px;
        background-image: url('../assets/arrow.svg');
    }
</style>