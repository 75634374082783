<template>
    <div class="scan v-form v-form__scan active">
        <div class="scan__elements">
            <video class="scanner"></video>
            <div class="demo-module" v-if="ENV_PROJECT === 'demo'">
                <div class="demo-notification">
                    Можете загрузить любое фото, <br>
                   с&nbsp;целью демонстрации мы представим, что на&nbsp;нем есть чек
                </div>
                <v-btn
                    depressed
                    color="primary"
                    class="next-btn accept__btn"
                    block
                    @click="startDemo"
                    >
                    <div class='btn-content-container'>
                        <img src="@/assets/loader.svg" alt="loader" class="loader-icon"  :class="{'hidden': !isLoading}"/>
                        Сделать фото
                    </div>
                </v-btn>
            </div>
            <p class="scan-alertText">Убедитесь, что в&nbsp;области отчётливо&nbsp;виден&nbsp;QR&#8209;код</p>
            <p class="recovery recovery-scan" @click="$router.push({name:'LcUpload'})">Не считывается&nbsp;QR&#8209;код?</p>
        </div>
        <canvas id="canvas" style="overflow:auto"></canvas>
    </div>
</template>
<script>
import QrScanner from 'qr-scanner';
import jwt_decode from 'jwt-decode'
import moment from 'moment'
import {mapActions} from 'vuex'
export default {
    data(){
        return {
            qrScanner: undefined,
            ENV_PROJECT: process.env.VUE_APP_ENV_PROJECT,
            user_id: jwt_decode(this.$cookies.get("cng_token"))["id"],
            isLoading: false
        }
    },
    methods: {
        startDemo(){
            this.isLoading = true
            const currentDate = moment().utc().format("YYYYMMDDTHHmm")
            // console.log(moment.locale('ru'))
            // console.log(moment().utc().format("YYYYMMDDTHHmm"))
            const data = {
                account_id: this.user_id,
                summary: 696,
                time: currentDate,
                fn: 69696,
                fd: 234456,
                fp: 123435,
                type: 'manual'
            }
            fetch(`${process.env.VUE_APP_NT}/demo`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                }).then(response => {
                    
                        return response.json();
                    })
                    .then(data => {
                        if(data.status == 400){
                            alert('Error')
                            this.isLoading = false
                        }
                        else {
                            if(data.data){
                                this.getUserNotification()
                                this.isLoading = false
                                this.$router.push('/success')
                            } else {
                                this.isLoading = false
                                this.$router.push('/success')
                            }
                           
                        }
                });
        },   
        startScan() {
            if (!this.qrScanner) {
                let videoElem = this.$el.querySelector(".scanner");
                this.qrScanner = new QrScanner(videoElem, result => {
                    let res = this.decodeStr(result.data);
                    if (!res) {
                        // Неподходящий формат
                        return;
                    }
                    if (!res.fn || !res.fp || !res.i || !res.n || !res.s || !res.t) {
                        // Отствствуют данные
                        return;
                    }
                    function dataURItoBlob(dataURI) {
                        // convert base64/URLEncoded data component to raw binary data held in a string
                        var byteString;
                        if (dataURI.split(",")[0].indexOf("base64") >= 0)
                            byteString = atob(dataURI.split(",")[1]);
                        else
                            byteString = unescape(dataURI.split(",")[1]);
                        // separate out the mime component
                        var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
                        // write the bytes of the string to a typed array
                        var ia = new Uint8Array(byteString.length);
                        for (var i = 0; i < byteString.length; i++) {
                            ia[i] = byteString.charCodeAt(i);
                        }
                        return new Blob([ia], { type: mimeString });
                    }
                    var canvas = document.getElementById("canvas");
                    var video = document.querySelector("video");
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    canvas
                        .getContext("2d")
                        .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                    this.selectedFile = dataURItoBlob(canvas.toDataURL());
                    this.qrScanner.stop();
                    let formData = new FormData();
                    formData.append("file", this.selectedFile);
                    formData.append("account_id", this.user_id);
                    formData.append("time", res.t);
                    formData.append("operation", res.n);
                    formData.append("summary", res.s);
                    formData.append("fd", res.i);
                    formData.append("fn", res.fn);
                    formData.append("fp", res.fp);
                    formData.append("type", 'scan');
                    fetch(`${process.env.VUE_APP_QR}`, {
                        method: "POST",
                        body: formData
                    })
                        .then(response => {
                        if (response.ok) {
                            this.status = "success";
                            return response.json();
                        }
                        else {
                            this.qrScanner.start();
                            return;
                        }
                    });
                }, { returnDetailedScanResult: true });
            }
            this.qrScanner.start();
        },
        decodeStr(str) {
            console.log(str);
            try {
                let kv = str.split("&");
                let res = {};
                for (let i = 0; i < kv.length; i++) {
                    let tmp = kv[i].split("=");
                    res[tmp[0]] = tmp[1];
                }
                return res;
            }
            catch (e) {
                console.log(e);
                return false;
            }
        },
        ...mapActions({
            getUserNotification: "getUserNotification",           
        })
    },
    mounted(){
        this.startScan()
    },
    destroyed() {
        this.qrScanner.stop();
    }

}
</script>

<style scoped>

.v-form__scan {
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 47px 40px 32px 40px; */
}

.scan {
    display: none;
}
.scan.active{
    display: block;
    position: relative;
}

.scan-alertText{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.015em;

    color: #1A1A1A;
}

.recovery-scan {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important; 
    line-height: 100% !important;
    text-align: center !important;
    letter-spacing: -0.015em !important;

    /* Blue/50 */

    color: #4F70E4 !important;
    margin-top: -38px !important;
    margin-top: 12px !important;
}

.scanner {
    width: 255.66px !important;
    height: 253px !important;
    background: #DADADA;
    border: 1px solid #DB4100;
    border-radius: 8px;
    margin-bottom: 48px;
}

.scan__elements {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0px !important;
    align-items: center;
}

.demo-module{
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}

.demo-notification{
    background: #DCE2FA;
    padding: 10px 25px;
    margin-bottom: 12px;
    border-radius: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.015em;

    color: #1A1A1A;

}

.scan.active::before,
.scan.active::after,
.scan__elements::before,
.scan__elements::after {
    content: '';
    position: absolute;
    background-image: url('../../assets/angle.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 29px;
    height: 29px;
    z-index: 1;
}

.scan__elements::before {
    top: -17px;
    left: -0px;
    transform: rotate(90deg);
}

.scan__elements::after {
    top: 240px;
    left: -0px;
}

.scan.active::before{
    top: 32px;
    right: 20px;
    transform: rotate(180deg);
}

.scan.active::after {
    top: 289px;
    right: 20px;
    transform: rotate(270deg);
}
</style>
