<template>
    <div>
        <HeaderNav :auth="$store.state.isAuth"></HeaderNav>

        <div class="wrapper">
            <div class='container'>
                <div class="notification-backBtn" @click="$router.push({name:'notifications'})">
                    Назад
                </div>
                <div class="notification-image" v-if="this.notification_item?.notification_data.image !== undefined || this.notification_item?.notification_data.image !== ''">
                    <img  :src="this.getNotificationImage( this.notification_item?.notification_data.image)" alt="изображение из письма" />
                </div>
                <div class="notification-title">
                    {{ this.notification_item?.notification_data.title_message }}
                </div>
                <div class="notification-text" v-html="this.notification_item?.notification_data.text_message">
                  
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue';

    export default {
    components: { HeaderNav },
    data(){
        return{
            notification_item: {},

        }
    },
    methods:{
        getNotificationItem(){
            console.log(this.$store.state.notificationList)
            this.notification_item = this.$store.state.notificationList.filter(item => item?.id == this.$route.params.id)[0]
        },
        getNotificationImage(pic){
            if(pic !== undefined){
                return require(`@/assets/img/${pic}`)
            }
                return require(`@/assets/img/Notification_img_1.png`)
         
        }
    },
    watch:{
        '$store.state.notificationList': function() {
            this.notification_item = this.$store.state.notificationList.filter(item => item?.id == this.$route.params.id)[0]
        },
        // notification_item(){
        //     this.notification_item = this.$store.state.notificationList.filter(item => item?.id == this.$route.params.id)[0]
        // },

    },
    mounted(){
       this.getNotificationItem()
        
    }
}

</script>

<style lang="scss" scoped>
    .container{
        padding: 20px 20px 32px 20px;
    }
    .notification-backBtn{
        text-align: start;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: -0.015em;
        color: #4F70E4;
        cursor: pointer;
        margin-bottom: 28px;
    }
    .notification-image{
        width: 100%;
        margin-bottom: 12px;
    }
    .notification-image img{
        object-fit: contain;
        width: 100%;
    }
    .notification-title{
        text-align: start;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.015em;

        color: #1A1A1A;
        margin-bottom: 16px;
    }
    .notification-text{
        text-align: start;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.015em;

        color: #1A1A1A;
    }
</style>