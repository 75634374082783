<template>
    <div class="wrapper-notification">
        <div 
        class="notification-container"
        v-for="item in notifications"
        :key="item.id"
        @click="openNotification(item.id)"
        >
            <div class="notification-title">
                <div class="notification-content-title__new" :class="{'visibleNewNotification': !item.status}"></div>
                <div class="notification-date">
                {{ getTimeAgo(item.created_at)}}
                </div>
            </div>
            <div class="notification-content">
                <div class="notification-content__title">
                    <span>{{item?.notification_data?.title_notification}}</span>
                   
                </div>
                <div class="notification-content__text">
                    {{item?.notification_data?.text_notification}}
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import {mapActions} from 'vuex'
    export default {
        props:{
            notifications: {type: Array, required: true}
        },
        data(){
            return{
        
            }
        },
        methods:{
            ...mapActions({
            changeStatusNotification: "changeStatusNotification",
          
            }),
            // getTime(date){
            //     console.log(moment())
            //   return  moment(date, "YYYY-MM-DD hh:mm:ss").fromNow()
            // },
            openNotification(id){
                this.$router.push({name:'NotificationSingle', params:{id:id}})
                this.changeStatusNotification(id)
            },
            getTimeAgo(dateProps){
                if (!dateProps) {
                    return null;
                }
                const date = new Date(dateProps)
                let utcZone = moment.utc()
                let CurrentZone = moment.utc()
                CurrentZone.local()
                let resultUTC = CurrentZone.hours() - utcZone.hours()
                const seconds = Math.floor((new Date() - date) / 1000 - 60*60 *resultUTC);

                let interval = Math.floor(seconds / 31536000);
                if (interval > 1) {
                    if(interval < 5){
                        return interval + ' года назад';
                    }
                    if(interval >= 5)  return interval + ' лет назад';
               
                } else if(interval == 1){
                    return interval + ' год назад';
                }

                interval = Math.floor(seconds / 2592000);
                if (interval > 1 ) {
                    if(interval < 5){
                        return interval + ' месяца назад';
                    }
                    if(interval >= 5) return interval + ' месяцев назад';
                }   else if(interval == 1){
                    return interval + ' месяц назад';
                }

                interval = Math.floor(seconds / 86400);
                if (interval > 1) {
                    if(interval < 5 || (interval >= 22 && interval <= 24)){
                        return interval + ' дня назад';
                    }
                    if(interval == 21) return interval + ' день назад';
                    if(interval >= 5) return interval + ' дней назад';
                } else if(interval == 1){
                    return interval + ' день назад';
                }

                interval = Math.floor(seconds / 3600);
                if (interval > 1) {
                    if(interval < 5 || interval > 21){
                        return interval + ' часа назад';
                    }
                    if(interval >= 5 && interval < 21)  return interval + ' часов назад';
                    if(interval == 21)  return interval + ' час назад';
                } else if(interval == 1){
                    return interval + ' час назад';
                }

                interval = Math.floor(seconds / 60);
                if (interval > 1) {
                    if(interval < 5 || (interval >= 22 && interval <= 24) || (interval >= 32 && interval <= 34) || (interval >= 42 && interval <= 44) || (interval >= 52 && interval <= 54)){
                        return interval + ' минуты назад';
                    }
                    if(interval == 21 && interval == 31 && interval == 41 && interval == 51 ) return interval + ' минута назад';
                    if(interval >= 5) return interval + ' минут назад';
                } else if(interval == 1){
                    return interval + ' минуту назад';
                }

                if(seconds < 10) return 'сейчас';

                return Math.floor(seconds) + ' секунд назад';
            }
        },
        mounted(){
            console.log(this.notifications)
        }
    }
</script>

<style lang="scss" scoped>
    .wrapper-notification {
        // min-height: 320px;
        margin-top: 0 !important;
        gap: 12px;
    }

    .notification-container{
        position: relative;
        display: flex;
        flex-direction: column;
        background: #F1F1F1;
        min-width: 285px;
        border-radius: 10px;
        font-family: 'Inter';
        font-style: normal;
        font-size: 10px;
        line-height: 20px;
        margin-bottom: 12px;
        padding: 12px 16px 12px 16px;
        cursor: pointer;
    }
    .notification-title{
        display: flex;
        justify-content: end;
        align-items: center;
        width: 100%;
        margin-bottom: 12px;
    }
    .notification-content{
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 100%;
        min-width: 180px;
        
    }
    .notification-content__title{
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 4px;

    }
    .notification-content__title span{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: #1A1A1A;
        text-align: start;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
    }
    .notification-content-title__new{
        display: none;
        position: absolute;
        left: 17px;
        top: 12px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #FA73A4;
        margin-right: 12px;
 
    }
    .visibleNewNotification{
        display: block;
    }
    .notification-content__text{
        width: 100%;
        text-align: start;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;

        letter-spacing: -0.015em;

        color: #1A1A1A;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;

    }
    .notification-date{
        display: flex;
        align-items: end;
        justify-content: right;
        width: 50%;
        min-width: 68px;
        font-weight: 400;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        text-align: right;
        letter-spacing: -0.015em;
        color: #808080;
    }
</style>