<template>
  <v-app>
    <div id="app">
      <Sidebar></Sidebar>
      <PopupNotification v-show="this.$store.state.isVisiblePopupNotification && $route.path !== '/' && $route.path !== '/desktop_plug'"></PopupNotification>
      <router-view></router-view>
    </div>
  </v-app>
  
</template>

<script>
import {mapActions} from 'vuex'
import PopupNotification from './components/Popup-notification.vue';
import Sidebar from './components/Sidebar-menu.vue';
export default {
  components: { Sidebar,
       PopupNotification 
      },
      data() {
      return {
        isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i
          .test(navigator.userAgent),
        windowWidth: window.innerWidth
      }
    },
    methods: {
        ...mapActions({
            getUserData: "getUserData",
            getUserNotification: "getUserNotification",
            getQrcodes: "getQrcodes"
        }),
        onResize() {
          this.windowWidth = window.innerWidth
          this.redirect()
        },
        redirect(){

          if(!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i
          .test(navigator.userAgent))){

          this.$router.push({ name: "desktop_plug" });
          }
        }
    },
    watch:{
        '$route.path': function() {
          this.redirect()
            },
    },
    mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
      this.onResize()
      this.redirect()
      this.getUserData();
      this.getQrcodes()
      this.getUserNotification();
      // this.$store.commit('changeNotifications', arrNotification)
    },
    beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },     
    
    
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
// @font-face {
//     font-display: swap;
//     font-family: 'Vercetti';
//     src: url('../public/fonts/Vercetti.woff') format('woff'),
//         url('../public/fonts/Vercetti.woff2') format('woff2');
//     font-weight: 400, 700;
//     font-style: normal, bold;
// }

@font-face {
    font-display: swap;
    font-family: 'Vercetti';
    src: local("Vercetti"),
     url('../public/fonts/Vercetti-Regular.ttf') format('truetype');
    font-weight: 400, 700;
    font-style: normal, bold;
}

@font-face {
    font-display: swap;
    font-family: 'PT Sans';
    src: url('../public/fonts/PTSans.ttf') format('truetype');
       
    font-weight: 400, 700;
    font-style: normal, bold;
}
@font-face {
    font-display: swap;
    font-family: 'Korataki';
    src: url('../public/fonts/korataki_book.otf') format('opentype');
       
    font-weight:400;
    font-style: normal;
}
@font-face {
    font-display: swap;
    font-family: 'Korataki';
    src:  url('../public/fonts/korataki_bold.otf') format('opentype');
       
    font-weight:700;
    font-style: normal, bold;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  position: relative;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1A1A1A;
  background-color: #798DF7;
}

h1 {
  font-family: 'PT Sans';
  font-weight: 400;
  font-size: 20px;
}

.v-application p {
  margin-bottom: 0 !important;
}

.v-overlay{
  position: fixed;
  height: 100vh !important;

}

.v-label.v-label--active,
.v-label.v-label--active.primary--text{
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 100% !important;
  letter-spacing: -0.015em !important;

  color: #808080 !important;
  transform: translateY(-32px) translateX(-12px) scale(1) !important;
  
}


.manual_check .v-label.v-label--active,
.manual_check .v-label.v-label--active.primary--text{
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    /* identical to box height, or 133% */

    letter-spacing: -0.015em !important;
    transform: translateY(-30px) translateX(-12px) scale(1) !important;
    color: #1A1A1A !important;
}
.v-label{
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  /* identical to box height, or 143% */

  letter-spacing: -0.015em !important;

  color: #808080 !important;
}
.v-text-field--outlined legend{
  width: 0 !important;
}


.v-overlay__scrim{
  position: fixed;
  height: 100vh !important;
}

.v-navigation-drawer__content{
    width: 100% !important;
}
.check-input .v-input__slot{
  background: #ffffff !important;
  border: 1px solid #CCCCCC !important;
  border-radius: 10px !important;
}
.check-input.errorDate .v-input__slot{
  border-color: #DB4100 !important;
}
.check-input.errorDate fieldset{
  border-color: #DB4100 !important;
  border-radius: 10px !important;
}
.v-text-field--outlined.check-input  fieldset{
  border-radius: 10px !important;
  border: none;
}
.v-text-field--outlined.v-input--is-focused fieldset{
  border-radius: 10px !important;
  border: 1px solid #cccccc !important;
}
.v-list-item:before{
  border-radius: 0 !important;
}
.check-input__disabled.v-input input{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px !important;
line-height: 100%;
letter-spacing: -0.015em;
color: #808080 !important;

}
.v-input input {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px !important;
  letter-spacing: -0.015em;

  color: #1A1A1A !important;
}

.check .check-input__disabled.v-input input{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 100%;
  letter-spacing: -0.015em;

  color: #1A1A1A !important;
}

.check-input.v-input input{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  /* identical to box height, or 143% */

  letter-spacing: -0.015em;

  color: #1A1A1A !important;
  }

</style>
