<template>
    <div class="wrapper">
        <div class="check-titleBlock">
            <h1 class="check-titleBlock__title">Мои чеки</h1>
            <div class="check-title__counter">
                чеков: {{ checkList.length }}
            </div>
        </div>
        <div 
        class="check-container"
        v-for="check in checkList"
        :key="check.id"
        @click="$router.push({name:'LcSingle', params:{id:check.id}})"
        >
            <div class="check__date">
                {{ getDate(check.create_date) }}
            </div>
            <div 
                class="check__status" 
                :class="{'verified': check.status === 'verified', 'rejected': check.status === 'not verified'}"  
            >
            {{ statuses[check.status] }}
            </div>
            <div class="check__arrow">
                
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
    export default {
        props:{
            checkList: {type: Array, required: true}
        },
        data(){
            return{
                statuses: {
                "wait": "Проверка",
                "verified": "Принято",
                "not verified": "Отклонено",   
                },
            }
        },
        methods:{
            getDate(fullDate){
            let utcZone = moment.utc()
            let CurrentZone = moment.utc()
            CurrentZone.local()
            let resultUTC = CurrentZone.hours() - utcZone.hours()   
            const date = new Date(moment(fullDate, "YYYY-MM-DDTHH:mm").format("YYYY-MM-DDTHH:mm"))
            return moment(+date + 1000 * 60*60 *resultUTC).format("DD.MM.YYYY - HH:mm")
            }
        }
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        // min-height: 420px;
        margin-bottom: 20px !important;
        margin-top: 0 !important;
        padding: 0 !important;
        gap: 0px !important;
    }
    .check-titleBlock{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;
    }
    .check-titleBlock__title{
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.005em;
        color: #1A1A1A;
    }
    .check-title__counter{
        min-width: 107px;
        min-height: 26px;
        text-align: center;
        background: #F77988;
        border-radius: 79px;
    }
    .check-title__counter{
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 130%;
        letter-spacing: -0.005em;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .check-container{
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 44px;
        background: #F7F7F7;
        border-radius: 10px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: -0.015em;
        color: #1A1A1A;
        margin-bottom: 12px;
        padding: 15px 8px;
        cursor: pointer;
    }
    .check__date{
        text-align: start;
        width: 54%;
        min-width: 121px;
        padding-right: 12px;
    }
    .check__status{
        font-size: 12px;
        background: #F5DFCC;
        border-radius: 5px;
        width: 30%;
        padding: 5px 10px;
        width: 83px;
    }
    .check__status.verified{
        background: #DEECDC;
    }
    .check__status.rejected{
        background: #FAE3DE;
    }
    .check__arrow{
        // width: 10%;
        position: absolute;
        right:12px;
        min-width: 10px;
        height: 10px;
        background-image: url('../assets/arrow.svg');
    }
</style>