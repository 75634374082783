import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueJWT from 'vuejs-jwt'
import { VueMaskDirective } from 'v-mask'
import store from './store'

Vue.config.productionTip = false
Vue.use(require('vue-cookies'))
Vue.use(VueJWT)
Vue.use(store)
Vue.directive('mask', VueMaskDirective);
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
